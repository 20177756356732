import React, { useState } from 'react';
import Tab from './Tab';

const Tabs = (props) => {
  const isBrowser = typeof window !== 'undefined';
  let queryParams = isBrowser && new URLSearchParams(window.location.search);
  const urlTab = queryParams && queryParams.get('tab');

  if (urlTab) {
    queryParams.delete('tab');
    isBrowser &&
      window.history.replaceState(null, '', window.location.pathname);
  }

  const [activeTab, setActiveTab] = useState(
    urlTab ? urlTab : props.children[0].props.url
  );

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="tabs">
      <ul className="tab-list">
        {props.children.map((child) => {
          const { label, url } = child.props;

          return (
            <Tab
              activeTab={activeTab}
              key={url}
              label={label}
              url={url}
              onClick={onClickTabItem}
            />
          );
        })}
      </ul>
      <div className="tab-content flex justify-center">
        {props.children.map((child) => {
          if (child.props.url !== activeTab) return undefined;
          return child.props.children;
        })}
      </div>
    </div>
  );
};

export default Tabs;
