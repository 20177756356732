import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    const { url, onClick } = this.props;
    onClick(url);
  };

  render() {
    const {
      onClick,
      props: { activeTab, label, url },
    } = this;

    let className = 'tab-list-item';

    if (activeTab === url) {
      className += ' tab-list-active';
    }

    return (
      <li className={className} onClick={onClick}>
        <h3>{label}</h3>
      </li>
    );
  }
}

export default Tab;
