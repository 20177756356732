import React from 'react';
import Layout from '../components/Layout';
import Hero from '../components/Hero-all';
import Footer from '../components/Footer';
import Tabs from '../components/Tabs/Tabs';

import Fall from './wtc/Fall';
import Spring from './wtc/Spring';
import Tools from './wtc/Tools';
import Winter from './wtc/Winter';

const WTCPage = () => {
  return (
    <Layout>
      <Hero image="/img/hero/wtc.jpg" title="i learned &lowbar;." />
      <section className="container max-w-4xl mx-auto px-4">
        <div className="container">
          <h2 className="font-headline font-semibold mb-2">
            Follow my carpentry journey.
          </h2>
          <p className="mb-6">
            In fall of 2023, I started my journey as a carpenter at Seattle
            Central College's Wood Technology Center.{' '}
          </p>
        </div>

        <Tabs>
          <div label="fall '23" url="fall">
            <Fall />
          </div>
          <div label="winter '24" url="winter">
            <Winter />
          </div>
          <div label="spring '24" url="spring">
            <Spring />
          </div>
          <div label="tools" url="tools">
            <Tools />
          </div>
        </Tabs>

        <Footer />
      </section>
    </Layout>
  );
};

export default WTCPage;
